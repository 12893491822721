<template>
    <OOverlay v-if="isLoading" />
    <ORowContainer>
        <ODataGrid
            ref="grid"
            :data="dataArray"
            hide-multiselect-column
            noRowsFound
            hideGridMenu
            hideActionColumn
            :gridApi="{
                exportData: (dataGrid) => { customExport(dataGrid) }
            }"
            :key="localGridKey"
        >
            <template v-if="gridJson.fields && gridJson.fields.length > 0">
                <template v-for="col in gridJson.fields">
                    <TableLayoutColumnGroup v-if="col.groupId" :column="col" :fields="gridJson.fields"/>
                    <TableLayoutColumn v-else-if="col.field && col.aggregateType == 'CUSTOM'" :columnData="col" :fields="gridFields"/>
                    <TableLayoutColumn v-else-if="col.field" :columnData="col" :fields="gridFields"/>
                    <OColumn v-else :colId="getRandomColId()" :headerName="$t('Column not found')"></OColumn>
                </template>
            </template>
            <OColumn v-else :colId="getRandomColId()" :headerName="$t('Column not found')"></OColumn>
        </ODataGrid>
    </ORowContainer>
</template>

<script setup lang="ts">
    import { ref, Ref, watch, onMounted } from 'vue';
    import { utils, $t } from 'o365-utils';
    import { getOrCreateProcedure, API } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { Overlay as OOverlay } from 'o365-ui-components';
    import TableLayoutColumnGroup from "status.vue.components.tableLayoutComponents.TableLayoutColumnGroup.vue";
    import TableLayoutColumn from "status.vue.components.tableLayoutComponents.TableLayoutColumn.vue";

    const emit = defineEmits(["loaded", "error"]);

    const props = defineProps({
        layoutId: {
            type: String,
            default: null,
            required: false
        },
        urlParams: {
            type: String,
            default: null,
            required: false
        },
        procedureParams: {
            type: Object,
            default: null,
            required: false
        },
        config: {
            type: String,
            default: null,
            required: false
        },
        procedure: {
            type: String,
            default: null,
            required: false
        },
        // used for template export
        title: {
            type: String,
            default: null,
            required: false
        },
        gridKey: {
            type: String,
            default: null,
            required: false
        },
        forceReload: {
            type: Boolean,
            default: false,
            required: false
        }
        
    });

    const localGridKey: Ref<string> = ref(crypto.randomUUID());
    const gridFields: Ref<any> = ref([]);
    const gridJson:Ref<any> = ref({fields:[]});
    const dataArray:Ref<Array<any>> = ref([]);
    const procParams:Ref<Object|null> = ref(null);
    const isLoaded:Ref<boolean> = ref(false);
    const isLoading:Ref<boolean> = ref(true);
    const ID:Ref<string> = ref("");
    const tableLayoutKey:Ref<string> = ref(crypto.randomUUID());

    ID.value = props.layoutId;
    procParams.value = getProcedureParams();

    const dsLayout = getOrCreateDataObject({
        id: 'dsLayout'+crypto.randomUUID(),
        viewName: 'sviw_O365_TableLayouts',
        maxRecords: 1,
        whereClause: "Name = '" + ID.value + "'",
        selectFirstRowOnLoad: true,
        fields: [
            { name: "ID", type: "number" },
            { name: "Title", type: "string" },
            { name: "Layout", type: "string" },
            { name: "StoredProcedure", type: "string" },
            { name: "ParametersForPreviewData", type: "string" },
        ]
    });
    
    var loadDataProc:any = null;

    function load(){
        isLoading.value = true;
        if(props.procedure && props.config){
            loadDataProc = getOrCreateProcedure({ id: props.procedure+crypto.randomUUID(), procedureName: props.procedure, useAlert: false, useGroupedRequests: false });
            gridJson.value = JSON.parse(props.config);
            executeProcedure();
        }else if(ID.value){
            dsLayout.load().then(async ()=>{

                if(dsLayout.current){
                    loadDataProc = getOrCreateProcedure({ id: dsLayout.current.StoredProcedure+crypto.randomUUID(), procedureName: dsLayout.current.StoredProcedure, useAlert: false, useGroupedRequests: false });
                    gridJson.value = JSON.parse(dsLayout.current.Layout??"{}");
                    executeProcedure();
                    getGridFields();
                }
            });
        }
    }
    load();
    async function executeProcedure(){
        localGridKey.value = crypto.randomUUID();
        loadDataProc.execute(procParams.value).then((response:any)=>{
            dataArray.value = sortData(gridJson.value, response.Table);
            emit("loaded");
            isLoaded.value = true;
            isLoading.value = false;
        }).catch((error:any)=>{
            isLoading.value = false;
            if(error.message && error.message.includes("@")){
                let field = error.message.split("@")[1].split("'")[0]; // get required field from error message
                emit("error", error.message, field);
            }else{
                console.log(error);
                emit("error", error.message, null);
            }
        });
    }

    function sortData(pOptions, pData: any[]) {
        const sortOrder = pOptions.fields?.filter(x => x.sortOrder);

        if (sortOrder && sortOrder.length > 0) {
            sortOrder.sort((a, b) => a.sortOrder! - b.sortOrder!);
            sortOrder.forEach(item => {
                pData.sort(sortByKey(item.field, item.sortDirection, getColumnType(item.type)));
            });
        }
        return pData;
    }

    /** Get a sort function for a specific item key */
    function sortByKey(pKey: string, pOrder: 'asc' | 'desc' | null | undefined, pType: string) {
        return (_a: any, _b: any) => {
            let a = _a[pKey];
            let b = _b[pKey];
            if (pType === 'string') {
                if (a && b) {
                    if (a !== null) { a = a.toString().toUpperCase(); }
                    if (b !== null) { b = b.toString().toUpperCase(); }
                }
            } else if (['datetime', 'date'].includes(pType)) {
                a = new Date(a as string);
                b = new Date(b as string);
            }
            switch (pType) {
                case 'number':
                    return pOrder === 'asc' ? (a - b) : (b - a);
                case 'string':
                case 'date':
                case 'datetime':
                case 'uniqueidentifier':
                    return pOrder === 'asc' ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);
                default:
                    return 0;
            }
        };
    }

    async function getGridFields() {
        if(!dsLayout.current.StoredProcedure){
            return;
        }
        try {
            gridFields.value = await API.requestGet(`/api/status/layouts/fields/${dsLayout.current.StoredProcedure}`, {showErrorDialog: false});
        }catch(error: any) {
            import('o365-vue-services').then((alertModule) => {
                alertModule.alert(`Failed to load layout fields: ${error.message}`, 'danger', { autohide: true, delay: 8000, });
            });
        }
    }

    function getColumnType(type:string){
        if(type == "Number"){
            return "number"
        }else if(type == "Date"){
            return "date"
        }else{
            return "string"
        }
    }

    function getProcedureParams() {
        if (props.procedureParams) {
            let cleanedUpObj = {};
            Object.entries(props.procedureParams).forEach(([key, value]) => {
                if (value) {
                    cleanedUpObj[key] = value;
                } else {
                    cleanedUpObj[key] = null;
                }
            });
            return cleanedUpObj
        } else if (props.urlParams) {
            let url = props.urlParams;
            if(url.includes("?")){
                url = url.split("?")[1];
            }
            let procParams = {};
            url.split("&").forEach((param) => {
                let temp = param.split("=");
                if (temp[0] == "name"){  // to not pass the table name as param
                    ID.value = temp[1];
                }else{
                    procParams[temp[0]] = temp[1];
                }
            });
            return procParams;
        } else {
            return {}
        }
    }
    const isExporting = ref(false);

    function customExport(dataGridControl){
        isExporting.value = true;
        const columns = dataGridControl.dataColumns.columns.filter(x => !x.hide && !x.colId.startsWith('o365_')).map(x => ({field: x.field, format: x.format, type: x.type, caption: x.headerName}));
        const data = dataGridControl.utils.processedData.map(item => item);
        console.log(dsLayout.current.Title ?? props.title)

        let excelTitle = dsLayout.current.Title ?? props.title;

        // added nt prefix, because there are cases where this is running on instance with proxy stil enabled
        fetch('/nt/api/status/table-layouts/export', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                "responseType": "blob",
            }, 
            body: JSON.stringify({
                excelTitle: excelTitle,
                columns: columns,
                data: data,
            }),
        }).then(async (response) => {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', (excelTitle ?? "Table Layout") + ".xlsx");
            document.body.appendChild(link);
            link.click();
            isExporting.value = false;
        }).catch( () => {
            isExporting.value = false;
        });

    }

    function getRandomColId(){
        return "noColumn" + crypto.randomUUID();
    }

    watch(() => props.config, () => {
        if(!isLoaded.value){
            procParams.value = getProcedureParams();
            load();
        } else {
            gridJson.value = JSON.parse(props.config);
            // tableLayoutKey.value = crypto.randomUUID();
        }
    }, { deep: true });

    watch(() => props.procedureParams, () => {
        procParams.value = getProcedureParams();
        load();
    }, { deep: true });

    watch(() => props.layoutId, () => {
        ID.value = props.layoutId;
        dsLayout.recordSource.whereClause = "Name = '" + ID.value + "'";
        load();
    }, { deep: true });

    watch(() => props.gridKey, () => {
        localGridKey.value = crypto.randomUUID();
        procParams.value = getProcedureParams();
        if(props.forceReload){
            load();
        }
    });
    
</script>